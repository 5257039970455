import debug from 'debug'
import { createLogic, StandardAction } from 'redux-logic'
import { Subject, Observable } from 'rxjs'
import { filter, tap, map, takeUntil, retry, repeat } from 'rxjs/operators'
import { webSocket } from 'rxjs/webSocket'
import { store, Dependency } from 'AppSrc/store'
import { RootState } from 'AppSrc/store'
import {
  msgReceived,
  WsActionTypes,
  wsConnect,
  wsDisconnect,
  wsMsgError,
  wsMsgListen,
  wsMsgSend,
  wsMsgStopListening,
} from './reducer'
import { MsgMeta, MsgPayload } from './types'

debug.enable('websocket/logic:*')
const log = debug('websocket/logic:log')
// const info = debug('websocket/logic:info')
// const error = debug('websocket/logic:error')

const isServer = typeof window === 'undefined'

let WS_SERVER: string
if (!isServer) {
  const wsType = window.location.protocol === 'http:' ? 'ws' : 'wss'
  const port = window.location.port ? `:${window.location.port}` : ''
  WS_SERVER = `${wsType}://${window.location.hostname}${port}/ws`
  log(`ws server = ${WS_SERVER}`)
}

const websocketLogic = createLogic<
  RootState, // State
  MsgPayload, // Payload
  MsgMeta, // Meta
  Dependency, // Dependency
  WsActionTypes // Context
>({
  type: wsMsgListen.type,
  cancelType: wsMsgStopListening.type,
  latest: true, // take latest only
  warnTimeout: 0, // long running logic

  processOptions: { failType: wsMsgError.type },

  ...(!isServer && {
    process({
      action$,
      cancelled$,
    }: {
      action$: Observable<StandardAction<string, MsgPayload, MsgMeta>>
      cancelled$: Subject<void>
    }) {
      const wsSubject$ = webSocket<MsgPayload>({
        url: WS_SERVER,
        openObserver: { next: () => store.dispatch(wsConnect()) },
        closeObserver: { next: () => store.dispatch(wsDisconnect()) },
      })

      // send message on wsMsgSend action
      action$
        .pipe(
          filter(action => action.type === wsMsgSend.type),
          tap(action => wsSubject$.next(action.payload)),
          takeUntil(cancelled$)
        )
        .subscribe()

      // dispatch msgReceived with payload from server
      // on any incoming messages
      // returning obs subscribes to it
      return wsSubject$.pipe(
        map(msg => msgReceived(msg)),
        retry({ delay: 1500 }),
        repeat({ delay: 1500 })
      )
    },
  }),
})

export default websocketLogic
